import moment from "moment";
import { useSelector } from "react-redux";
import { SPHERESLIST } from "../common/helpers";

/**
 * @deprecated Use useGoalGroupFilter
 */
const useGoalFilter = ({
  startDate,
  endDate,
  finished = false,
  activeOnly = true,
  sphere = true,
}) => {
  const { id: categoryId } = SPHERESLIST.find((item) => item.sphere === sphere) || {};
  const goals = useSelector(({ goals }) => goals.items);

  return goals.filter(({ 
    category_id, 
    date_start, 
    date_end, 
    finished_at, 
    is_active 
  }) =>
    // @TODO: should we count cancelled goals as well? {finished_at:null, is_active:false}
    (!activeOnly || is_active)
    && (!categoryId || categoryId == category_id) // filter by sphere (category)
    && (Boolean(finished_at) === finished) // filter finished goals
    && (!startDate || moment(date_start).isSameOrAfter(startDate)) // filter by start date
    && (!endDate || moment(date_end).isSameOrBefore(endDate)) // filter by end date
  );
};

export default useGoalFilter;