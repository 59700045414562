import types from "../actionTypes";
import { updateGoalsVersion } from "./GoalsActionCreator";
import { getApi } from "./CommonActionCreator";

export const setTasksAction = (period, tasks) => ({
  type: types.SET_TASKS,
  payload: { period, tasks },
});

export const deleteTaskAction = (period, taskId) => ({
  type: types.DELETE_TASK,
  payload: { period, taskId },
});

// export const updateTaskAction = (period, taskId, taskData) => ({
//   type: types.UPDATE_TASK,
//   payload: { id: taskId, data: taskData, period },
// });

export const setSubtasksAction = (period, subtasks) => ({
  type: types.SET_SUBTASKS,
  payload: { period, subtasks },
});

export const deleteSubTaskAction = (period, taskId) => ({
  type: types.DELETE_SUBTASK,
  payload: { id: taskId, period },
});

export const LoadingTaskActionCreator = (loading) => ({
  type: types.TASKS_LOADING,
  payload: loading,
});

export const createTask = (params) => {
  return function (dispatch, getState) {
    if (!params) {
      return Promise.resolve();
    }

    return getApi(dispatch, getState)
      .post(`/target/`, params)
      .then((data) => {
        dispatch(updateGoalsVersion());
        return data;
      })
  };
};

export const searchSubtasks = (period, params) => {
  return (dispatch, getState) =>
    getApi(dispatch, getState)
      .get('/sub_target/', { params })
      .then(data => {
        dispatch(setSubtasksAction(period, data.results));
        return data;
      });
}

export const updateSubtarget = (period, subtargetId, params) => {
  return (dispatch, getState) => {
    if (!params) {
      return Promise.resolve();
    }

    return getApi(dispatch, getState)
      .patch(`/sub_target/${subtargetId}/`, params)
      .then((data) => {
        dispatch(deleteSubTaskAction(period, subtargetId));
        dispatch(updateGoalsVersion());
        return data;
      })
  };
}
