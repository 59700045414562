import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Menu, Layout, Icon, Spin } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import { useMediaQuery } from "react-responsive";

import { ReactComponent as HomeIcon } from "../../assets/home.svg";
import {
  SPHERESLIST,
  useQuery
} from "../../common/helpers";
import device from "../../common/deviceSizes";
import { getSphereList } from "../../redux/actionCreators/SphereActionCreator";

const { Sider } = Layout;

const Sidebar = ({
  isMenuExpanded,
  setMenuExpanded,
}) => {
  const { pathname } = useLocation();
  const listOfSpheres = useSelector(({ user }) => user.categories || []);
  const dispatch = useDispatch();
  const query = useQuery();
  const period = query.get("period");
  
  const [areIconsLoading, setIconsLoading] = useState(false);
  
  const isOnMobile = useMediaQuery({
    query: device.mobile,
  });

  const isOnEnterSpherePage = SPHERESLIST.find(
    (sphere) => sphere.path === pathname
  );

  useEffect(() => {
    setMenuExpanded(isOnMobile ? false : true);
  }, [isOnMobile, setMenuExpanded]);

  useEffect(() => {
    if (!listOfSpheres.length) {
      setIconsLoading(true);
      dispatch(getSphereList())
        .then(() => {
          setIconsLoading(false);
        });
    }
  }, [listOfSpheres]);

  return (
    <SideSections width={isMenuExpanded ? 80 : 10} style={{ height: "100vh" }}>
      {isMenuExpanded && (
        <PageSizeWrapper
          boxshadow={
            isMenuExpanded ? "0px 0px 12px rgba(0, 0, 0, 0.5)" : "none"
          }
        >
          <HomeLink to="/" onClick={() => isOnMobile && setMenuExpanded(false)}>
            <HomeIcon />
          </HomeLink>

          <SidebarMenu pagelocation={pathname}>
            {areIconsLoading
              ? SPHERESLIST.map((sphere) => (
                  <Menu.Item key={sphere.title}>
                    <LightSpin />
                  </Menu.Item>
                ))
              : listOfSpheres.map((sphere) => (
                  <Menu.Item key={sphere.title}>
                    <NavLink
                      to={R.propOr(
                        "",
                        "path",
                        SPHERESLIST.find((item) => item.sphere === sphere.code)
                      )}
                      onClick={() => isOnMobile && setMenuExpanded(false)}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: sphere.svg_code }}
                      />
                    </NavLink>
                  </Menu.Item>
                ))}
          </SidebarMenu>
        </PageSizeWrapper>
      )}
      {isOnMobile && (
        <Icon
          style={{
            fontSize: "20px",
            color: isOnEnterSpherePage && !period ? "#fff" : "#f85c71",
            position: "absolute",
            top: 0,
            padding: "13px 10px",
            right: "-30px",
            zIndex: 1,
          }}
          onClick={() => setMenuExpanded(!isMenuExpanded)}
          type={isMenuExpanded ? "caret-left" : "caret-right"}
        />
      )}
    </SideSections>
  );
};

const LightSpin = styled(Spin)`
  .ant-spin-dot-item {
    background: #fff;
  }
`;

const HomeLink = styled(NavLink)`
  position: absolute;
  svg {
    margin-top: 20px;
    padding: 4px;
  }
`;

const PageSizeWrapper = styled.div`
  height: 100vh;
  display: flex;
  width: 80px;
  background: linear-gradient(180deg, #f85c71 0%, #1d4e89 100%);
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  @media ${device.mobile} {
    box-shadow: ${(prop) => prop.boxshadow};
    svg {
      width: 35px;
    }
  }
`;

const SidebarMenu = styled(Menu)`
  background: transparent;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  svg {
    fill: #f6eeec;
    padding: 3px;
  }
  li a {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .ant-menu-item {
    height: 85px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  li.ant-menu-item.ant-menu-item-selected,
  li.ant-menu-item.ant-menu-item-active {
    background-color: transparent;
  }
  height: 370px;
  @media ${device.mobileS} {
    position: absolute;
  }
  @media ${device.mobileL} {
    position: absolute;
    height: 320px;
  }
  @media ${device.tablet} {
    height: 370px;
  }
  @media ${device.largeDevice} {
    height: 450px;
  }
`;

const SideSections = styled(Sider)`
  position: relative;
  background: linear-gradient(180deg, #f85c71 0%, #1d4e89 100%);
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media ${device.mobile} {
    position: absolute;
    transition: all 0.5s ease;
    z-index: 2;
  }
`;

export default Sidebar;
