import { getApi } from "./CommonActionCreator";
import { SetCategoryActionCreator } from "./UserActionCreators";

export const getSphereList = () => {
  return function (dispatch, getState) {
    return getApi(dispatch, getState)
      .get(`/category/`)
      .then((data) => {
        dispatch(SetCategoryActionCreator(data));
        return data;
      });
  };
};

export const getSphereData = (sphereId) => {
  return function (dispatch, getState) {
    return getApi(dispatch, getState)
      .get(`/category_user_data/${sphereId}/`);
  };
};

export const addSphereData = (sphereId, params) => {
  return function (dispatch, getState) {
    return getApi(dispatch, getState)
      .post(`/category_user_data/`, {
        category: sphereId,
        ...params,
      });
  };
};

export const updateSphereData = (sphereId, params) => {
  return function (dispatch, getState) {
    return getApi(dispatch, getState)
      .put(`/category_user_data/${sphereId}/`, {
        category: sphereId,
        ...params,
      });
  };
};
