import types from "../../actionTypes";

const initialState = null;

const GoalToEditReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_GOAL_TO_EDIT:
      return payload;
    default:
      return state;
  }
};

export default GoalToEditReducer;
