import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useGoalFilter from "./useGoalFilter";
import { searchAllGoals } from "../redux/actionCreators/GoalsActionCreator";

const useGoalSearch = ({
  startDate,
  endDate,
  sphere,
  active,
  params = {},
  refreshAttributes = [],
  isAllowed = false,
}) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(({ auth }) => auth.accessToken);
  const { isLoading, isLoaded } = useSelector(({ goals }) => goals);
  // const { isLoading, isLoaded } = {isLoading:false, isLoaded:false};

  useEffect(() => {
    if (isAllowed && !isLoaded && !isLoading) {
      dispatch(searchAllGoals({
        size: 1000,
        // active: true,
        // finished: true,
        ...params
      }))
    }
  }, [
    [
      accessToken, 
      ...refreshAttributes
    ],
  ]);

  return {
    hasMore: false,
    isLoading,
    goals: useGoalFilter({ startDate, endDate, sphere, active }),
  };
};

export default useGoalSearch;
