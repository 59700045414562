import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationEN from "./en.json";
import translationRu from "./ru.json";
import translationUa from "./ua.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRu,
  },
  uk: {
    translation: translationUa,
  },
};

const userLanguage = window.navigator.userLanguage || window.navigator.language;
let startLanguage = "en";
switch (userLanguage && userLanguage.slice(0, 2)) {
  case "ru":
    startLanguage = "ru";
    break;

  case "uk":
    startLanguage = "uk";
    break;

  default:
    break;
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: startLanguage,
    fallbackLng: startLanguage,

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
