import types from "../actionTypes";

export const AuthSuccessActionCreator = (userData) => ({
  type: types.LOGIN_SUCCESS,
  payload: userData,
});

export const RefreshTokenActionCreator = (token) => ({
  type: types.REFRESH_TOKEN,
  payload: token,
});

export const SetAvatarActionCreator = (avatar) => ({
  type: types.SET_AVATAR,
  payload: avatar,
});

export const UpdateFieldActionCreator = (newField) => ({
  type: types.UPDATE_FIELD,
  payload: newField,
});

export const LogOutActionCreator = () => ({
  type: types.LOG_OUT,
});
