import types from "../../actionTypes";

const initialState = {
  version: Date.now(),
};

const GoalsReducer = (state = initialState, { type, payload }) => {
  const { period } = payload || {};
  switch (type) {
    // TODO: add isLoading state
    case types.SET_TASKS:
      // @TODO: filter by task id to prevent dupes?
      const { tasks } = payload;
      return {
        ...state,
        [period]: tasks,
      };
    case types.UPDATE_TASK:
      const { id, data } = payload;
      return {
        ...state,
        [period]: state[period].map(task => {
          return task.id === id ? {...task, ...data} : task;
        }),
      };
    case types.DELETE_TASK:
      const newState = {};
      for (period in state) {
        newState[period] = state[period].filter(task => task.id !== payload);
      }
      return newState;
    case types.NEW_VERSION:
      return {
        // ...state,
        version: Date.now(),
      };
    default:
      return state;
  }
};

export default GoalsReducer;
