import types from "../actionTypes";
import { getApi } from "./CommonActionCreator";
import { setTasksAction } from "./TasksActionCreator";

export const GetGoalsActionCreator = (goalsData) => ({
  type: types.LOAD_GOALS,
  payload: goalsData,
});

export const AddGoalActionCreator = (newGoal) => ({
  type: types.ADD_NEW_GOAL,
  payload: newGoal,
});

export const DeleteGoalActionCreator = (id) => ({
  type: types.DELETE_GOAL,
  payload: id,
});

export const EditGoalActionCreator = (editedGoal) => ({
  type: types.EDIT_GOAL,
  payload: editedGoal,
});

export const LoadingGoalActionCreator = (loading) => ({
  type: types.GOALS_LOADING,
  payload: loading,
});

export const GoalToEditActionCreator = (goalToEdit) => ({
  type: types.SET_GOAL_TO_EDIT,
  payload: goalToEdit,
});

export const updateGoalsVersion = () => ({
  type: types.NEW_VERSION,
  payload: {},
});

export const updateGoal = (goalId, params) => {
  return function (dispatch, getState) {
    if (!params) {
      return Promise.resolve();
    }

    return getApi(dispatch, getState)
      .patch(`/goal/${goalId}/`, params)
      .then((data) => {
        dispatch(EditGoalActionCreator(data));
        return data;
      })
  };
};

// export const createGoal = (params) => {
//   return function (dispatch, getState) {
//     if (!params) {
//       return Promise.resolve();
//     }

//     return getApi(dispatch, getState)
//       .post(`/goal/`, params)
//       .then((data) => {
//         dispatch(updateGoalsVersion());
//         dispatch(AddGoalActionCreator(data));
//         return data;
//       })
//   };
// };

export const getGoal = (goalId) => {
  return (dispatch, getState) => 
    getApi(dispatch, getState)
      .get(`/goal/${goalId}/`);
};

export const searchAllGoals = (params) => {
  return async (dispatch, getState) => {
    dispatch(LoadingGoalActionCreator(true));
    await new Promise(r => setTimeout(r, 2000));
    return getApi(dispatch, getState)
      .get('/goal/', { params })
      .then(data => {
        dispatch(GetGoalsActionCreator(data));
        return data;
      })
      .catch(() => {
        dispatch(LoadingGoalActionCreator(false))
      });
  }
}

/**
 * @deprecated Use searchAllGoals()
 */
export const searchGoals = (period, params) => {
  return (dispatch, getState) => {
    dispatch(LoadingGoalActionCreator(true));
    
    return getApi(dispatch, getState)
      .get('/goal/', { params })
      .then(data => {
        dispatch(LoadingGoalActionCreator(false))
        dispatch(setTasksAction(period, data.results));
        return data;
      })
      .catch(() => {
        dispatch(LoadingGoalActionCreator(false))
      });
  }
}