import types from "../../actionTypes";

const initialState = { goals: false, targets: false };

const LoadingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.TASKS_LOADING:
      return { ...state, targets: payload };
    case types.GOALS_LOADING:
      return { ...state, goals: payload };
    default:
      return state;
  }
};

export default LoadingReducer;
