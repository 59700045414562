import types from "../../actionTypes";

const userData = localStorage.getItem('user');
const initialState = {
  ...(userData ? JSON.parse(userData) : {}),
  isUserAuthenticated: false,
  aimsTexts: [],
  achievedTexts: [],
};

const AuthReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOGIN_SUCCESS:
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...state,
          ...payload,
          isUserAuthenticated: true,
        })
      );
      return { 
        ...state, 
        ...payload, 
        isUserAuthenticated: true 
      };
    case types.REFRESH_TOKEN:
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...state,
          accessToken: payload,
        })
      );
      return { ...state, accessToken: payload };
    case types.SET_AVATAR:
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...state,
          avatar: payload,
        })
      );
      return { ...state, avatar: payload };
    case types.UPDATE_FIELD:
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...state,
          ...payload
        })
      );
      return {...state, ...payload}
    case types.LOG_OUT:
      localStorage.removeItem("user");
      return { ...initialState };

    default:
      return state;
  }
};

export default AuthReducer;
