import React from "react";
import { Icon } from "antd";
import { useLocation } from "react-router-dom";
import moment from "moment";
import * as R from "ramda";
import "moment/locale/ru";
import "moment/locale/uk";
import "moment/locale/en-gb";

import { ReactComponent as MentalIcon } from "../assets/mental.svg";
import { ReactComponent as HealthIcon } from "../assets/health.svg";
import { ReactComponent as LearningIcon } from "../assets/learning.svg";
import { ReactComponent as RelationshipIcon } from "../assets/relationship.svg";
import { ReactComponent as AffairsIcon } from "../assets/suitcase.svg";
import i18n from "../translations/i18n";
import axiosInstance from "../axios/axiosInstance";

export const BASEURL = 'http://river.yourcofounder.com.ua:8000/';
export const getMediaUrl = path => path ? BASEURL + 'media/' + path.split('media/')[1] : '';

export const SPHERES = {
  health: "health",
  learning: "learning",
  relationship: "relationship",
  mental: "mental",
  affairs: "affairs",
};

export const SPHERESLIST = [
  {
    id: 1,
    sphere: SPHERES.health,
    path: `/${SPHERES.health}`,
    icon: <HealthIcon />,
    title: "Здоровье",
  },
  {
    id: 2,
    sphere: SPHERES.learning,
    path: `/${SPHERES.learning}`,
    icon: <LearningIcon />,
    title: "Интеллектуальное развитие",
  },
  {
    id: 3,
    sphere: SPHERES.relationship,
    path: `/${SPHERES.relationship}`,
    icon: <RelationshipIcon />,
    title: "Взаимоотношения",
  },
  {
    id: 4,
    sphere: SPHERES.mental,
    path: `/${SPHERES.mental}`,
    icon: <MentalIcon />,
    title: "Духовное развитие",
  },
  {
    id: 5,
    sphere: SPHERES.affairs,
    path: `/${SPHERES.affairs}`,
    icon: <AffairsIcon />,
    title: "Трудовая деятельность",
  },
];

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

moment.locale(i18n.t("lang"));
export const capitalize = R.replace(/^.| ./g, R.toUpper);

export const currentYear = moment().year();
export const currentMonthInGenetiveCase = capitalize(
  moment().format("D MMMM").replace(/\d+ /g, "")
);
export const month = capitalize(moment().format("MMMM"));
export const fullMonthDate = `${month} ${currentYear}`;
export const currentWeek = fullMonthDate + " II Неделя";
export const today = capitalize(moment().format("DD MMMM YYYY"));
export const currentDayPointsFormat = moment().format("DD.MM.YYYY");

export const transitions = ["height", "opacity"];

export const additionalTokenRefresh = (callbackFunc, stopLoadingAndLogout) => {
  console.log('additionalTokenRefresh!!');
  const userData = localStorage.getItem("user");
  return axiosInstance
    .post("/auth/jwt/refresh/", {
      refresh: userData && JSON.parse(userData).refreshToken,
    })
    .then(({ data }) => {
      const newToken = data && data.access;
      if (callbackFunc) {
        callbackFunc(newToken, true).catch(() => {
          console.log('additionalTokenRefresh ERROR. Logging out!');
          stopLoadingAndLogout && stopLoadingAndLogout();
        });
      }
    });
};

export const getTimeLeft = (to, from) => {
  const dateFormat = "DD.MM.YYYY";
  const formatedStartDate = moment(from).format(dateFormat);
  const formatedEndDate = moment(to).format(dateFormat);
  if (
    moment(formatedEndDate, dateFormat).diff(
      moment(formatedStartDate, dateFormat),
      "days"
    ) >= 0
  ) {
    return (
      moment(formatedEndDate, dateFormat).diff(
        moment(formatedStartDate, dateFormat),
        "days"
      ) + 1
    );
  }
};

export const spinIcon = (
  <Icon
    type="loading-3-quarters"
    style={{ fontSize: 12, color: "#f85c71" }}
    spin
  />
);

export const PERIODS = {
  day: "day",
  year: "year",
  week: "week",
  month: "month",
};

export const handleOpenLinkInNewTab = (link) => {
  const win = window.open(link, '_blank');
  if (win != null) {
    win.focus();
  }
}

export const throttle = (fn, interval) => {
  let lastTime;
  return function throttled() {
      let timeSinceLastExecution = Date.now() - lastTime;
      if(!lastTime || (timeSinceLastExecution >= interval)) {
          fn.apply(this, arguments);
          lastTime = Date.now();
      }
  };
}
