import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  List,
  Modal,
  Progress,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import styled from "styled-components";
import device from "./deviceSizes";

const { Text } = Typography;
const theme = {
  color: {
    red: '#F85C71',
    input: '#909FAB',
  }
};

export const PageTitle = styled.h2`
  text-align: left;
  font-weight: 200;
  color: #1d4e89;
  font-size: 50px;
  margin-bottom: 20px;
  z-index: 99;
  @media ${device.mobileS} {
    color: ${(prop) => (prop.color ? prop.color : "#fff")};
    font-size: 20px;
    margin-bottom: 5px;
  }
  @media ${device.mobileL} {
    color: ${(prop) => (prop.color ? prop.color : "#fff")};
    font-size: 24px;
    margin-bottom: 10px;
  }
  @media ${device.desktop} {
    margin-left: 30px;
    font-size: 60px;
    line-height: 73px;
  }
    @media ${device.laptop} {
    font-size: 44px;
    margin-bottom: 10px;
    font-weight: 300;
    margin-left: 40px;
    line-height: 52px;
  }
  @media ${device.tablet} {
    font-size: 34px;
    margin-bottom: 10px;
    font-weight: 300;
  }
  :hover {
    cursor: pointer;
  }
`;

export const PlanCover = styled(Row)`
  .planCover {
    overflow: hidden;
    border-radius: 10px;
    background: ${(prop) =>
      prop.hovering ? "rgba(26, 26, 26, 0.9)" : "transparent"};
    transition: 500ms ease-in-out;
    width: 100%;
    height: 100%;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    position: absolute;
  }
  .ant-typography {
    color: ${(prop) => (prop.hovering ? "#fff" : "rgba(0, 0, 0, 0.65)")};
  }
  .ant-list-empty-text {
    opacity: 0;
  }
  .ant-list-items {
    height: 14vh;
    overflow-y: auto;
    padding-bottom: 10px;
    cursor: pointer;
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
  :hover {
    .planCover {
      background: rgba(26, 26, 26, 0.9);
    }
    .ant-typography {
      color: #fff;
    }
    ul,
    svg,
    p,
    .ant-spin {
      opacity: 1;
      transition: 500ms ease-in-out;
    }
  }
  @media ${device.mobile} {
    .ant-list-items {
      height: ${(prop) => (prop.isperiodactive ? "35vh" : "14vh")};
      min-height: ${(prop) => prop.isperiodactive && "80%"};
      padding: ${(prop) => prop.isperiodactive && "5px 20px"};
    }
    svg {
      opacity: ${(prop) => (prop.isperiodactive ? 1 : 0)};
    }
  }
  @media ${device.littleDevice} {
    .planCover {
      border-radius: 0px;
    }
  }
  @media ${device.laptop} {
    .ant-list-items {
      height: 16vh;
    }
  }
  @media ${device.largeDevice} {
    :hover {
      .planCover {
        background: rgba(26, 26, 26, 0.9);
      }
      .ant-typography {
        color: #fff;
      }
      ul,
      svg,
      p,
      .ant-spin {
        opacity: 1;
        transition: 500ms ease-in-out;
      }
    }
  }
  @media ${device.desktop} {
    .ant-list-items {
      height: 17vh;
    }
  }
`;

export const InfoCard = styled(Card)`
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.08);
  border-radius: 0px 10px 10px 0px;
  color: #707070;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  height: 220px;
  text-align: justify-content;
  overflow: hidden;
  h3 {
    margin-bottom: 27px;
  }
  p {
    max-height: 90px;
    overflow-y: auto;
    overflow-x: hidden;
    outline: none;
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
  @media ${device.mobile} {
    box-shadow: none;
    color: #fff;
    font-size: 11px;
    line-height: 14px;
    height: 110px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 0px;
    }
    h3 {
      margin-bottom: 10px;
      color: #fff;
    }
    p {
      max-height: 1000px;
      font-weight: 200;
      text-align: justify;
    }
    .ant-card-body {
      padding: 0 15px;
    }
  }
  @media ${device.mobileL} {
    font-size: 12px;
    line-height: 17px;
    .ant-card-body {
      padding: 0px 20px;
    }
  }
  @media (min-width: 960px) and (max-width: 1280px) {
    height: 180px;
    font-size: 14px;
    line-height: 17px;
    color: #909fab;
    .ant-card-body {
      padding: 20px 25px;
    }
    h3 {
      margin-bottom: 10px;
    }
  }
  @media ${device.laptop} {
    height: 160px;
    font-size: 18px;
    line-height: 22px;
    .ant-card-body {
      padding: 5px 25px;
    }
    p {
      font-size: 15px;
      line-height: 22px;
      color: #909fab;
    }
    h3 {
      margin-bottom: 0px;
      line-height: 50px;
    }
  }
`;

export const SubTitle = styled.h3`
  color: #d56159;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  @media ${device.mobileS} {
    font-size: 14px;
    line-height: 20px;
    padding: 7px 15px;
  }
  @media ${device.mobileL} {
    font-size: 16px;
    line-height: 18px;
    padding: 10px 15px;
  }
  @media ${device.tablet} {
    font-size: 21px;
    line-height: 22px;
    margin-bottom: 22px;
  }
  @media ${device.laptop} {
    font-size: 25px;
    line-height: 27px;
    margin-bottom: 21px;
  }
  @media ${device.desktop} {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 30px;
  }
`;

export const UserCardsSubTitle = styled.h3`
  color: #d56159;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  @media ${device.mobileS} {
    font-size: 14px;
    line-height: 20px;
  }
  @media ${device.mobileL} {
    font-size: 16px;
    line-height: 18px;
  }
  @media ${device.tablet} {
    font-size: 22px;
    line-height: 22px;
  }
  @media ${device.laptop} {
    font-size: 25px;
    line-height: 27px;
    margin-bottom: 21px;
  }
  @media ${device.desktop} {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 25px;
  }
`;

export const ThinText = styled(Text)`
  letter-spacing: 0.7px;
  font-weight: 300;
  font-size: 16px;
`;

export const SimpleButton = styled(Button)`
  border: none;
  @media ${device.mobile} {
    padding: 0 3px;
  }
`;

export const BlackText = styled(Text)`
  font-weight: 600;
  font-size: 44px;
  line-height: 54px;
  color: #3b3b3b;
  @media ${device.mobileS} {
    font-size: 17px;
    line-height: 29px;
  }
  @media ${device.mobileL} {
    font-size: 20px;
    line-height: 30px;
  }
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
  @media ${device.laptop} {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const LittleBlackText = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3b3b3b;
  @media ${device.mobileS} {
    font-size: 12px;
    line-height: 14px;
  }
  @media ${device.mobileL} {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;
  }
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const RegularCardText = styled(Text)`
  font-weight: 300;
  color: #fff;
  @media ${device.mobileS} {
    font-size: 10px;
    line-height: 12px;
  }
  @media ${device.mobileL} {
    font-size: 12px;
    line-height: 15px;
  }
  @media ${device.tablet} {
    font-size: 12px;
    line-height: 15px;
  }
  @media ${device.laptop} {
    font-size: 14px;
    line-height: 17px;
  }
  @media ${device.desktop} {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const RegularText = styled(Text)`
  color: #3b3b3b;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  @media ${device.laptop} {
    font-size: 18px;
    line-height: 22px;
  }
  @media ${device.desktop} {
    font-size: 19px;
    line-height: 22px;
  }
`;

export const RegularWideText = styled(Text)`
  font-size: 23px;
  letter-spacing: 0.74px;
  color: #3b3b3b;
  display: block;
  @media ${device.tablet} {
    padding: 0 10px;
  }
  @media ${device.largeDevice} {
    padding: 0 20px;
  }
`;

export const ThinRedText = styled(ThinText)`
  color: #f85c71;
`;

export const TaskInput = styled(Input)`
  margin-bottom: 50px;
  input {
    letter-spacing: 0.7px;
    font-weight: 300;
    font-size: 22px;
    border: none;
    color: #b5b5b5;
  }
  .ant-input-affix-wrapper,
  .ant-input:not(:first-child) {
    padding-left: 70px;
  }
`;

export const AddTaskButton = styled(Button)`
  display: flex;
  align-items: center;
  span {
    letter-spacing: 0.7px;
    font-weight: 300;
    border: none;
    color: #909fab;
  }
  @media ${device.mobile} {
    svg {
      width: 15px;
    }
    span {
      padding-left: 10px;
    }
  }
  @media ${device.mobileS} {
    margin-bottom: 10px;
    span {
      font-size: 10px;
      line-height: 12px;
    }
  }
  @media ${device.mobileL} {
    margin: 10px 15px;
    span {
      font-size: 12px;
      line-height: 15px;
    }
  }
  @media ${device.tablet} {
    margin: 10px 0;
    svg {
      width: 15px;
    }
    span {
      font-size: 16px;
      line-height: 20px;
      padding-left: 20px;
    }
  }
  @media ${device.laptop} {
    svg {
      width: 30px;
    }
    span {
      font-size: 16px;
      line-height: 20px;
      padding-left: 20px;
    }
  }
  @media ${device.desktop} {
    svg {
      width: 30px;
    }
    span {
      font-size: 18px;
      line-height: 22px;
      padding-left: 21px;
    }
  }
`;

export const VerticalProgress = styled(Progress)`
  transform: rotate(270deg);
  text-align: center;
  width: 135px;
  margin: 0 10px 60px 0;
  .ant-progress-inner {
    background: transparent;
    border: 1px solid #b5b5b5;
  }
`;

export const MediumText = styled(Text)`
  color: #3b3b3b;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
`;

export const Column = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const TaskIconsWrapper = styled.div`
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardWithShadow = styled(Card)`
  border: none;
  border-radius: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  .ant-card-body {
    border-radius: 20px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    background: linear-gradient(
      180deg,
      rgba(248, 92, 113, 0.7) 0%,
      rgba(29, 78, 137, 0.7) 100%
    );
    padding: 0;
    overflow: hidden;
  }
  @media ${device.mobileS} {
    margin: 0 10px 15px 10px;
    .ant-card-body {
      height: 185px;
    }
  }
  @media ${device.mobileL} {
    margin: 0 15px 20px 15px;
    .ant-card-body {
      height: 220px;
    }
  }
  @media ${device.tablet} {
    width: 50%;
    padding: 10px;
    margin: 0;
    box-shadow: none;
    .ant-card-body {
      height: 220px;
    }
  }
  @media (min-width: 1279px) and (max-width: 1280px) {
    .ant-card-body {
      height: 220px;
    }
  }
  @media ${device.laptop} {
    margin-bottom: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    .ant-card-body {
      height: 188px;
      border: none;
    }
  }
  @media ${device.desktop} {
    border-top: 0px solid rgba(0, 0, 0, 0.07);
    margin-bottom: 25px;
    .ant-card-body {
      height: 240px;
    }
  }
`;

export const AddTaskCardWithShadow = styled(Card)`
  @media ${device.littleDevice} {
    border: none;
  }
  @media ${device.desktop} {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }
`;

export const TaskAmountText = styled(Text)`
  font-size: 20px;
  letter-spacing: 2.13px;
  color: #3b3b3b;
  font-weight: 500;
  @media ${device.mobileS} {
    font-size: 13px;
    font-weight: 800;
  }
  @media ${device.mobileL} {
    font-size: 14px;
  }
  @media ${device.tablet} {
    font-size: 17px;
  }
`;

export const StyledAutoComplete = styled(AutoComplete)`
  min-width: 300px;
  && .ant-select-dropdown-menu-item {
    background-color: red;
  }
  && .ant-select-selection__rendered {
    line-height: 35px;
    font-style: normal; 
    font-size: 16px; 
    color: ${theme.color.input}; 
    
    .ant-input.ant-select-search__field { 
      height: 38px; 
      color: ${theme.color.input}; 
      &:hover {
        border-color: ${theme.color.red}; 
        color: ${theme.color.red}; 
      }
    }
  }
`;

export const StyledSelect = styled(Select)`
  min-width: 300px;
  .ant-select-selection:hover { 
    border-color: ${theme.color.red}; 
  }
  .ant-select-selection:hover .ant-select-selection__rendered { 
    color: ${theme.color.red}; 
  }
  .ant-select-selection--single { 
    height: 38px; 
  }
  .ant-select-selection__rendered { 
    line-height: 35px; 
    margin-right: 11px; 
    margin-left: 11px; 
    font-style: normal; 
    font-size: 16px; 
    color: ${theme.color.input}; 
  }
  .ant-select-selection-selected-value { 
    padding-left: 3px; 
    text-transform: capitalize; 
  }
  .ant-select-dropdown-menu-item { 
    text-transform: capitalize; 
  }
`;

export const FormInputForAuth = styled(Input)`
  background-color: transparent;
  border-radius: 5px;
  padding: 0 20px;
  cursor: pointer;
  color: #fff;
  border: 1px solid #cacaca;
  :hover {
    box-shadow: 0 0 3px rgba(255, 255, 255, 0.3);
  }
  :focus {
    outline: none;
    box-shadow: 0 0 3px rgba(255, 255, 255, 0.3);
  }
  ::placeholder {
    color: #fff;
    font-size: 18px;
    opacity: 0.9;
  }
  @media ${device.mobileS} {
    width: 250px;
    height: 35px;
  }
  @media ${device.mobileL} {
    width: 300px;
  }
  @media ${device.tablet} {
    width: 400px;
    height: 40px;
  }
  @media ${device.laptop} {
    width: 350px;
    height: 45px;
  }
  @media ${device.desktop} {
    width: 400px;
    height: 53px;
  }
`;

export const ResponsiveImage = styled.img`
  width: 100%;
  display: block;
  object-fit: cover;
  box-shadow: 0px 3px 6px #00000029;
  height: ${(prop) => prop.height};
  @media ${device.mobile} {
    object-fit: fill;
  }
  @media ${device.largeDevice} {
    border-radius: 5px;
  }
`;

export const PurposeList = styled(Row)`
  position: absolute;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  padding: 35px 35px 0px 20px;
  width: 100%;
  height: 100%;
  .ant-empty-description {
    color: #fff;
  }
  @media ${device.mobile} {
    padding: 0;
  }
  @media ${device.tablet} {
    padding: 10px;
  }
`;

export const RightDirectionCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const ListWithMoreIcon = styled(List)`
  .anticon {
    color: #f85c71;
  }
  .ant-typography {
    font-weight: 300;
    word-break: break-word;
  }
  .ant-list-item {
    border-bottom: none;
  }
  @media ${device.mobileS} {
    .ant-typography {
      font-size: 10px;
      line-height: 12px;
    }
    .ant-list-item {
      margin: 5px 0 10px 0;
    }
  }
  @media ${device.mobileL} {
    margin-bottom: 40px;
    .ant-typography {
      font-size: 12px;
      line-height: 15px;
    }
    .ant-list-item {
      margin: 8px 0;
    }
  }
  @media ${device.tablet} {
    margin-bottom: 60px;
    .ant-typography {
      font-size: 16px;
      line-height: 18px;
    }
    .ant-list-item {
      margin: 8px 0;
    }
  }
  @media ${device.laptop} {
    margin-bottom: 60px;
    .ant-list-empty-text {
      padding: 0;
    }
    .ant-typography {
      font-size: 12px;
      line-height: 15px;
    }
    .ant-list-item {
      padding: 8px 0;
    }
  }
  @media ${device.desktop} {
    margin-bottom: 80px;
    .ant-list-empty-text {
      padding: 0;
    }
    .ant-typography {
      font-size: 18px;
      line-height: 21px;
    }
    .ant-list-item {
      padding: 10px 0;
    }
    .anticon {
      padding: 3px 0;
      font-size: 16px;
    }
  }
`;

export const Sticky = styled.div`
  position: sticky;
`;

export const ResponsiveSphereSticky = styled(Sticky)`
  @media ${device.laptop} {
    margin-bottom: 15px;
  }
  @media ${device.desktop} {
    margin-bottom: 70px;
  }
`;

export const ResponsiveDiv = styled.div`
  @media ${device.laptop} {
    margin-bottom: 25px;
  }
  @media ${device.desktop} {
    margin-bottom: 70px;
  }
`;

export const OverflowContent = styled(Col)`
  ::-webkit-scrollbar {
    width: 0px;
  }
  @media ${device.largeDevice} {
    margin-top: ${(prop) => prop.margin};
  }
`;

export const Title = styled(Text)`
  font-weight: 800;
  color: #3b3b3b;
`;

export const AddTaskTitle = styled(Text)`
  color: #fff;
  font-weight: 600;
  @media ${device.mobileS} {
    font-size: 16px;
    line-height: 20px;
  }
  @media ${device.mobileL} {
    font-size: 20px;
    line-height: 24px;
  }
  @media ${device.tablet} {
    font-size: 25px;
    line-height: 28px;
  }
  @media ${device.laptop} {
    font-size: 30px;
    line-height: 37px;
  }
  @media ${device.desktop} {
    font-size: 44px;
    line-height: 54px;
  }
`;

export const AddTaskSubtitle = styled(Title)`
  color: #3b3b3b;
  font-weight: 600;
  @media ${device.mobileS} {
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
    display: block;
  }
  @media ${device.mobileL} {
    font-size: 17px;
    line-height: 25px;
    padding-top: 15px;
    margin-bottom: 15px;
  }
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 24px;
    padding-top: 15px;
  }
  @media ${device.laptop} {
    font-size: 20px;
    line-height: 43px;
  }
  @media ${device.desktop} {
    margin: 20px 0;
    display: block;
    font-size: 28px;
    line-height: 34px;
  }
`;

export const TaskTitle = styled(Title)`
  font-weight: 600;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media ${device.mobileS} {
    padding: 15px 0px 10px 15px;
    font-size: 11px;
    line-height: 12px;
    width: 86%;
  }
  @media ${device.mobileL} {
    padding: 20px 0px 10px 20px;
    font-size: 14px;
    line-height: 15px;
    width: 90%;
  }
  @media ${device.tablet} {
    padding: 15px 0px 10px 20px;
    font-size: 13px;
    line-height: 15px;
    width: 90%;
  }
  @media ${device.laptop} {
    width: 93%;
    font-size: 18px;
    line-height: 22px;
    padding: 20px 0px 10px 20px;
  }
  @media ${device.desktop} {
    width: 95%;
    font-size: 24px;
    line-height: 29px;
    padding: 20px;
  }
`;

export const NormalText = styled(Text)`
  font-size: 23px;
  line-height: 29px;
  color: #3b3b3b;
  @media ${device.mobileS} {
    font-size: 10px;
    line-height: 12px;
  }
  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 16px;
  }
  @media ${device.tablet} {
    font-size: 18px;
    line-height: 20px;
    margin: 0 10px;
  }
  @media ${device.laptop} {
    font-size: 16px;
    line-height: 19px;
  }
  @media ${device.desktop} {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const LittleText = styled(Text)`
  font-size: 16px;
  line-height: 18px;
  color: #bebdbd;
`;

export const CardThinText = styled(Text)`
  color: #3b3b3b;
  @media ${device.mobileS} {
    font-size: ${(prop) => (prop.fontSize ? prop.fontSize : "10px")};
    line-height: 12px;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  @media ${device.mobileL} {
    font-size: ${(prop) => (prop.fontSize ? prop.fontSize : "13px")};
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  @media ${device.tablet} {
    font-size: ${(prop) => (prop.fontSize ? prop.fontSize : "14px")};
    line-height: 17px;
  }
  @media ${device.laptop} {
    font-size: 14px;
    line-height: 17px;
  }
  @media ${device.desktop} {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const ColWithRightTextAlign = styled(Col)`
  text-align: right;
`;

export const FullSizeRow = styled(Row)`
  width: 100%;
`;

export const IconButton = styled(Icon)`
  color: #f85c71;
  cursor: pointer;
`;

export const WhiteIconButton = styled(Icon)`
  color: #fff;
  cursor: pointer;
  @media ${device.mobileS} {
    padding: 15px 10px 10px 15px;
  }
  @media ${device.mobileL} {
    padding: 20px 10px 10px 10px;
  }
  @media ${device.tablet} {
    padding: 15px 10px 10px 10px;
  }
  @media ${device.laptop} {
    padding: 20px 20px 10px 20px;
  }
  @media ${device.desktop} {
    padding: 20px;
  }
`;

const MainButton = styled(Button)`
  padding: 11px 25px 15px 25px;
  border-radius: 30.5px;
  min-height: 45px;
  align-items: center;
  span {
    font-size: 16px;
    line-height: 16px;
  }
  @media ${device.laptop} {
    padding: 5px 15px;
    min-height: 35px;
    span {
      font-size: 14px;
      line-height: 14px;
    }
  }
`;

export const PrimaryButton = styled(MainButton)`
  background-color: #205698;
  border: none;
  span {
    color: #fff;
  }
`;

export const CancelButton = styled(MainButton)`
  background-color: #fff;
  border: 2px solid #f85c71;
  span {
    color: #f85c71;
  }
`;

export const CancelModalButton = styled(MainButton)`
  background-color: #fff;
  border: 2px solid #f85c71;
  span {
    color: #f85c71;
  }
  @media ${device.mobileS} {
    min-height: 20px;
    min-width: 100px;
    padding: 5px;
    span {
      font-size: 14px;
      line-height: 15px;
    }
  }
  @media ${device.mobileL} {
    min-height: 30px;
    min-width: 110px;
    padding: 4px;
    span {
      font-size: 15px;
      line-height: 18px;
    }
  }
  @media ${device.tablet} {
    min-height: 40px;
    min-width: 115px;
    padding: 5px;
    span {
      font-size: 17px;
      line-height: 20px;
    }
  }
  @media ${device.laptop} {
    min-height: 40px;
    min-width: 145px;
    span {
      font-size: 18px;
      line-height: 22px;
    }
  }
  @media ${device.desktop} {
    min-height: 45px;
    min-width: 140px;
    span {
      font-size: 19px;
      line-height: 22px;
    }
  }
`;

export const ConfirmModalButton = styled(MainButton)`
  background-color: #fff;
  border: 2px solid #1d4e89;
  span {
    color: #1d4e89;
  }
  @media ${device.mobileS} {
    min-height: 20px;
    min-width: 100px;
    padding: 5px;
    span {
      font-size: 14px;
      line-height: 15px;
    }
  }
  @media ${device.mobileL} {
    min-height: 30px;
    min-width: 110px;
    padding: 4px;
    span {
      font-size: 15px;
      line-height: 18px;
    }
  }
  @media ${device.tablet} {
    min-height: 40px;
    min-width: 115px;
    padding: 5px;
    span {
      font-size: 17px;
      line-height: 20px;
    }
  }
  @media ${device.laptop} {
    min-height: 40px;
    min-width: 145px;
    span {
      font-size: 18px;
      line-height: 22px;
    }
  }
  @media ${device.desktop} {
    min-height: 45px;
    min-width: 140px;
    span {
      font-size: 19px;
      line-height: 22px;
    }
  }
`;

export const PeriodTitle = styled(Text)`
  font-weight: 300;
  font-size: 26px;
  line-height: 34px;
  color: #3b3b3b;
  @media ${device.mobile} {
    font-size: 14px;
    font-weight: 900;
  }
`;

export const CenteredRow = styled(Row)`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const RowCenteredCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const ProfileCard = styled(Card)`
  padding: 7px 10px;
  border: none;
  @media ${device.littleDevice} {
    padding: 0px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    .ant-card-body {
      padding: 10px;
      margin-left: 10px;
    }
  }
  @media (min-width: 1279px) and (max-width: 1280px) {
    height: 320px;
  }
  @media ${device.laptop} {
    padding-left: 50px;
    height: 180px;
    .ant-card-body {
      padding: 15px;
      overflow: hidden;
      height: 160px;
    }
  }
  @media ${device.desktop} {
    padding-left: 85px;
    height: 270px;
    .ant-card-body {
      padding: 20px;
      height: 260px;
    }
  }
  @media ${device.largeDevice} {
    overflow: hidden;
  }
`;

export const ProfileCardTitle = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 25px;
  color: #000000;
  @media ${device.laptop} {
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const ProfileCardText = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  color: #3b3b3b;
  outline: none;
  @media ${device.mobileS} {
    font-size: 10px;
    font-weight: 400;
  }
  @media ${device.mobileL} {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }
  @media ${device.tablet} {
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
  }
  @media ${device.laptop} {
    font-size: 16px;
    line-height: 15px;
  }

  @media ${device.desktop} {
    font-size: 18px;
    line-height: 15px;
  }
`;

export const EnterForm = styled(Form)`
  @media ${device.tablet} {
    align-self: start;
  }
  .ant-row {
    @media ${device.mobileS} {
      margin-bottom: 0;
    }
    @media ${device.mobileL} {
      margin-bottom: 5px;
    }
    @media ${device.tablet} {
      margin-bottom: 8px;
    }
    @media ${device.largeDevice} {
      margin-bottom: 10px;
    }
  }
  .ant-input {
    background: transparent;
    border: 1px solid #d2d8dd;
    font-weight: 200;
    line-height: 18px;
    max-width: 360px;
    max-height: 40px;
    border-radius: 3px;
    @media ${device.mobileS} {
      font-size: 12px;
      @-moz-document url-prefix() {
        padding-top: 6px;
      }
    }
    @media ${device.mobileL} {
      font-size: 14px;
      @-moz-document url-prefix() {
        padding-top: 5px;
      }
    }
    @media ${device.tablet} {
      font-size: 15px;
      @-moz-document url-prefix() {
        padding-top: 8px;
      }
    }
    @media ${device.largeDevice} {
      font-size: 14px;
      @-moz-document url-prefix() {
        padding-top: 8px;
      }
    }
  }
  .ant-input::placeholder {
    font-weight: 200;
    line-height: 20px;
    font-size: 14px;
    @media ${device.mobileS} {
      font-size: 12px;
    }
  }
  .has-error input {
    border: 1px solid #ff4d4f;
    background-color: transparent;
  }
  .ant-form-explain {
    margin: 3px 0 0 0;
    font-size: 12px;
    color: #fff;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 200;
    @media ${device.mobileS} {
      font-size: 11px;
      margin: 0;
    }
    @media ${device.mobileL} {
      font-size: 11px;
      margin: 0;
    }
  }
  .ant-input:hover {
    background-color: transparent;
  }
`;

export const EnterButton = styled(Button)`
  text-shadow: none;
  color: #fff;
  width: 215px;
  height: 45px;
  border-radius: 31px;
  border: none;
  margin: 0 10px;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  font-weight: 400;
  min-width: fit-content;
  background: ${(props) =>
    props.type === "primary" ? "transparent" : "#205698"};
  border: ${(props) => (props.type === "primary" ? "2px solid #fff" : "none")};
  span {
    opacity: ${(props) => (props.type === "primary" ? "1" : "0.8")};
  }
  :hover {
    background: #fff;
    border: none;
    color: #205698;
    font-weight: 400;
  }
  :focus {
    font-weight: 400;
    background: ${(props) =>
      props.type === "primary" ? "transparent" : "#205698"};
    border: ${(props) =>
      props.type === "primary" ? "2px solid #fff" : "none"};
    color: #fff;
  }
  @media ${device.littleDevice} {
    font-weight: 300;
    border: ${(props) =>
      props.type === "primary"
        ? props.contrastcolor
          ? `1px solid ${props.contrastcolor}`
          : "1px solid #1D4E89"
        : "none"};
    color: ${(props) =>
      props.type === "primary"
        ? props.contrastcolor
          ? "#fff"
          : "#1D4E89"
        : "#fff"};
    :hover {
      background: #1d4e89;
      color: #fff;
    }
    :focus {
      background: ${(props) =>
        props.type === "primary" ? "transparent" : "#1D4E89"};
      color: ${(props) =>
        props.type === "primary"
          ? props.contrastcolor
            ? "#fff"
            : "#1D4E89"
          : "#fff"};
      border: 1px solid #fff;
    }
    @media ${device.mobileS} {
      width: 110px;
      margin-bottom: 5px;
      height: 30px;
      font-size: 12px;
      font-weight: 300;
    }
    @media ${device.mobileL} {
      width: 130px;
      margin-bottom: 5px;
      height: 35px;
      font-size: 14px;
      font-weight: 300;
    }
    @media ${device.tablet} {
      width: 190px;
      margin-bottom: 10px;
      height: 40px;
      font-size: 16px;
      font-weight: 300;
      border: ${(props) =>
        props.type === "primary"
          ? props.contrastcolor
            ? "1px solid #fff"
            : "1px solid #F85C71"
          : "none"};
      color: ${(props) =>
        props.type === "primary"
          ? props.contrastcolor
            ? "#fff"
            : "#F85C71"
          : "#fff"};
    }
`;

export const ButtonWrapper = styled(Form.Item)`
  max-width: 380px;
  .ant-form-item-children {
    display: flex;
    justify-content: space-between;
  }
`;

export const CenterAlignModal = styled(Modal)`
  .ant-modal-content {
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
  }
  .ant-modal-body {
    padding-top: 45px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .ant-modal-footer {
    padding: 0 0 30px 0;
    border-top: none;
    text-align: center;
    display: flex;
    justify-content: center;
  }
`;

export const TwoRowsCol = styled(Col)`
  @media ${device.tablet} {
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.largeDevice} {
    margin-top: ${(prop) => prop.margin};
  }
`;

export const TwoRowsColOverflowContent = styled(OverflowContent)`
  @media ${device.tablet} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const CircleCheckbox = styled(Checkbox)`
  color: #fff;
  margin: 5px 0 15px 0;
  @media ${device.mobileS} {
    margin: 3px 0 7px 0;
  }
  @media ${device.mobileL} {
    margin: 5px 0 10px;
  }
  .ant-checkbox-inner {
    border-radius: 14px;
    background-color: #fff;
    border-color: #d2d8dd;
    position: relative;
    ::after {
      content: "";
      width: 8px;
      height: 8px;
      background-color: #f85c71;
      border: none;
      border-radius: 8px;
      transform: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media ${device.mobileS} {
        width: 6px;
        height: 6px;
      }
      @media ${device.mobileL} {
        font-size: 7px;
        line-height: 7px;
      }
    }
  }
  .ant-checkbox-checked {
    ::after {
      display: none;
    }
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: none;
  }
  span {
    font-size: 13px;
    line-height: 20px;
    font-weight: 200;
    @media ${device.mobileS} {
      font-size: 10px;
      line-height: 15px;
    }
    @media ${device.mobileL} {
      font-size: 11px;
      line-height: 18px;
    }
  }
`;

export const ColoredSpin = styled(Spin)`
  width: 100%;
  height: 70px;
  .ant-spin-dot {
    width: 30px;
    height: 30px;
  }
  .ant-spin-dot-item {
    background: #f55b71;
    width: 15px;
    height: 15px;
  }
`;

export const CenteredColoredSpin = styled(ColoredSpin)`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CentralSuspense = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

export const AbsoluteCenteredColoredSpin = styled(CenteredColoredSpin)`
  position: absolute;
  top: 0;
`;
