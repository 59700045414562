import React from "react";
import styled from "styled-components";
import { Menu, Layout } from "antd";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { ReactComponent as DiaryIcon } from "../../assets/diary.svg";
import { ReactComponent as CharityIcon } from "../../assets/worldwide.svg";
import { ReactComponent as NewsIcon } from "../../assets/newspaper.svg";
import device from "../../common/deviceSizes";

const { Footer } = Layout;

const PageFoooter = ({ t }) => {
  const { diary, charity, news } = t("footer", { returnObjects: true });

  const isOnLargeDevice = useMediaQuery({
    query: device.largeDevice,
  });

  return (
    <MainFooter>
      <FooterMenu mode="horizontal">
        <FooterMenuItem key="charts">
          <NavLink to="/charts">
            <DiaryIcon style={{ width: isOnLargeDevice ? 30 : 27 }} />
            {isOnLargeDevice && <FooterText>{diary}</FooterText>}
          </NavLink>
        </FooterMenuItem>
        <FooterMenuItem key="charity">
          <NavLink to="/charity/animal">
            <CharityIcon style={{ width: isOnLargeDevice ? 30 : 27 }} />
            {isOnLargeDevice && <FooterText>{charity}</FooterText>}
          </NavLink>
        </FooterMenuItem>
        <FooterMenuItem key="news">
          <NavLink to="/news">
            <NewsIcon style={{ width: isOnLargeDevice ? 30 : 27 }} />
            {isOnLargeDevice && <FooterText>{news}</FooterText>}
          </NavLink>
        </FooterMenuItem>
      </FooterMenu>
    </MainFooter>
  );
};

const MainFooter = styled(Footer)`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  min-height: max-content;
  height: 12vh;
  align-items: center;
  padding: 8px 0;
  z-index: 3;
  overflow: hidden;
  .ant-menu-horizontal > .ant-menu-item > a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media ${device.mobile} {
    z-index: 0;
    padding: 4px 0;
    height: 8vh;
    min-height: 50px;
    .ant-menu {
      line-height: 0px;
    }
  }
`;

const FooterMenu = styled(Menu)`
  border: none;
`;

const FooterMenuItem = styled(Menu.Item)`
  text-align: center;
  border: none !important;
`;

const FooterText = styled.p`
  display: none;
  @media ${device.laptop} {
    margin: 5px 10px 0 10px;
    font-size: 13px;
  }
  @media ${device.desktop} {
    margin: 15px;
    font-size: 14px;
  }
  @media ${device.largeDevice} {
    display: block;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 14px;
    color: #1d4e89;
  }
`;

export default withTranslation()(PageFoooter);
