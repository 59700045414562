import types from "../actionTypes";
import { getApi } from "./CommonActionCreator";
import { UpdateFieldActionCreator } from "./AuthActionCreators";

export const SetCategoryActionCreator = (data) => ({
  type: types.SET_CATEGORY,
  payload: data,
});

export const GetCitiesActinoCreator = (language) => {
  return (dispatch, getState) =>
    getApi(dispatch, getState)
      .get("/city/", {
        headers: {
          'Accept-Language': language,
        },
      })
}

export const GetUserActionCreator = (userId) => {
  return (dispatch, getState) =>
    getApi(dispatch, getState)
      .get(`/user/${userId}/`)
}

export const PatchUserActionCreator = (userId, data, params = {}) => {
  return (dispatch, getState) =>
    getApi(dispatch, getState)
      .patch(`/user/${userId}/`, data, params)
      .then(data => {
        dispatch(UpdateFieldActionCreator(data));
        return data;
      })
}