import types from "../../actionTypes";

const initialState = {};

const SubtaskReducer = (state = initialState, { type, payload }) => {
  const { period } = payload || {};
  switch (type) {
    // TODO: add isLoading state
    case types.SET_SUBTASKS:
      // @TODO: filter by task id to prevent dupes?
      const { subtasks } = payload;
      return {
        ...state,
        [period]: subtasks,
      };
    case types.DELETE_SUBTASK:
      const { id: subtaskId } = payload;
      return {
        ...state,
        [period]: state[period].filter(({ id }) => id !== subtaskId),
      };
    case types.NEW_VERSION:
      return initialState;
    default:
      return state;
  }
};

export default SubtaskReducer;
