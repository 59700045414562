import axiosInstance from "../../axios/axiosInstance";
import axios from "axios";
import { RefreshTokenActionCreator, LogOutActionCreator } from "./AuthActionCreators";
  
export const getApi = (dispatch, getState) => {
  const apiInstance = getApiInstance(getState);
  const request = (url, method, params = {}, data = {}, retry = true) => apiInstance.request({
    url,
    method,
    data,
    ...params
  })
  .then(res => {
    // console.log('Res received:', {data: res.data});
    return res.data;
  })
  .catch(async error => {
    let errMsg = '';
    if (error.response) {
      // Request made and server responded
      let { data = {}, status } = error.response;
      errMsg = data.message || data;
      console.log('API Error:', { status, data });

      if (retry && status && [401, 403].includes(status * 1)) {
        const newToken = await handleTokenRefresh(dispatch, getState)
        if (newToken !== false) {
          return request(
            url, 
            method, 
            {
              ...params,
              headers: {
                Authorization: `JWT ${newToken}`,
              }, 
            },
            data, 
            false // we retry only once
          );
        } else {
          errMsg = 'Failed to receive new auth token!'
          console.log(errMsg + ' Will logout!');
          dispatch(LogOutActionCreator());
        }
      }

    } else if (error.request) {
      // The request was made but no response was received
      errMsg = 'Response not received';
      console.log('API Error: ' + errMsg, error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      errMsg = error.message;
      console.log('API Error: ' + errMsg);
    }

    throw new Error(errMsg);
  });

  return {
    get: (url, params) => request(url, 'get', params),
    put: (url, data, params) => request(url, 'put', params, data),
    post: (url, data, params) => request(url, 'post', params, data),
    patch: (url, data, params) => request(url, 'patch', params, data),
  }
}

const getApiInstance = (getState, params = {}) => {
  const { auth: { accessToken } = {} } = getState();
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `JWT ${accessToken}`,
    },
    ...params
  });
};

export const handleTokenRefresh = async (dispatch, getState) => {
  const { auth: { refreshToken } = {} } = getState();
  if (!refreshToken) {
    return Promise.resolve(false);
  }
  return axiosInstance
    .post('/auth/jwt/refresh/', {
      refresh: refreshToken,
    })
    .then(({ data }) => {
      const newToken = data && data.access;
      dispatch(RefreshTokenActionCreator(newToken));
      return newToken;
    })
    .catch((e) => {
      return false;
    });
}


// useEffect(() => {
//   let isSubscribed = true;
//   const getJob = (token, tokenRefreshNeeded) =>
//     axiosInstance
//       .get(`/user/${user_id}/`, {
//         headers: {
//           Authorization: `JWT ${token}`,
//         },
//       })
//       .then(res => {
//         if (isSubscribed) {
//           setJobText(res.data.job);
//           tokenRefreshNeeded &&
//             handleRefreshToken &&
//             handleRefreshToken(token);
//         }
//       })
//       .catch(console.log);
//   getJob(accessToken).catch(() => {
//     additionalTokenRefresh(getJob).catch(console.log);
//   });
//   return () => {
//     isSubscribed = false;
//   };
// }, [accessToken, user_id, handleRefreshToken]);