import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "antd/dist/antd.css";
import { connect, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as R from "ramda";

import Layout from "./components/Layout/Layout";
import routes, { enterRoute } from "./routes/routes";
import "./App.css";
import useGoalSearch from "./axios/useGoalSearch";
import {
  RefreshTokenActionCreator,
  AuthSuccessActionCreator,
  LogOutActionCreator,
} from "./redux/actionCreators/AuthActionCreators";
import { SwitchLanguageAction } from "./redux/actionCreators/LanguageActionCreators";
import { GetUserActionCreator } from "./redux/actionCreators/UserActionCreators";
import { CentralSuspense } from "./common/StyledComponents";

const App = ({ isUserAuthenticated, authSuccess, setLanguage, logOut }) => {
  const dispatch = useDispatch();
  
  const getUserData = () => {
    return JSON.parse(localStorage.getItem("user")) || {};
  }
  const hasUserAlreadyEntered = R.prop("isUserAuthenticated", getUserData());
  const [areUserLoading, setUserLoading] = useState(
    hasUserAlreadyEntered ? true : false
  );

  const { user_id } = getUserData();
  useEffect(() => {
    if (user_id && hasUserAlreadyEntered && !isUserAuthenticated) {
      dispatch(GetUserActionCreator(user_id))
        .then((data) => {
          authSuccess({
            ...getUserData(),
            ...data,
            user_id
          });
          const userLang = localStorage.getItem("lang");
          if (userLang) {
            setLanguage(userLang);
          }
        }).catch(e => {
          logOut();
        })
        .finally(() => {
          setUserLoading(false);
        })
    } else {
      setUserLoading(false);
    }
  }, []);

  const { isLoading: areGoalsLoading } = useGoalSearch({
    refreshAttributes: [isUserAuthenticated], 
    isAllowed: isUserAuthenticated
  });

  return (
    <Router>
      {!isUserAuthenticated ? (
        (areUserLoading || areGoalsLoading) ? (
          <CentralSuspense size="large" />
        ) : (
          <Route key={uuidv4()}>{enterRoute.component}</Route>
        )
      ) : (
        <Layout>
          <Switch>
            {routes.map((route) => (
              <Route key={uuidv4()} path={route.pathname}>
                {route.component}
              </Route>
            ))}
            <Route key={uuidv4()}>{enterRoute.component}</Route>
          </Switch>
        </Layout>
      )}
    </Router>
  );
};

const mapStateToProps = ({ auth }) => ({
  isUserAuthenticated: auth.isUserAuthenticated,
});
const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(LogOutActionCreator()),
  handleRefreshToken: (token) => dispatch(RefreshTokenActionCreator(token)),
  authSuccess: (userData) => dispatch(AuthSuccessActionCreator(userData)),
  setLanguage: (lang) => dispatch(SwitchLanguageAction(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
