import types from "../../actionTypes";

const initialState = {
    categories: [],
};

const UserReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case types.SET_CATEGORY:
            return {
                ...state,
                categories: payload
            };

        default:
            return state;
    }
};

export default UserReducer;
