import React from "react";
import styled from "styled-components";
import { Col, Layout } from "antd";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import LinkLogo from "../../assets/link.png";
import RetinaLinkLogo from "../../assets/link@2x.png";
import RedLogo from "../../assets/redLogo.png";
import RetinaRedLogo from "../../assets/redLogo@2x.png";
import device from "../../common/deviceSizes";
import { useQuery, SPHERESLIST } from "../../common/helpers";
import HeaderMenu from "./HeaderMenu";

const { Header } = Layout;

const PageHeader = () => {
  const { pathname } = useLocation();
  const isOnEnterSpherePage = SPHERESLIST.find(
    (sphere) => sphere.path === pathname
  );
  const isOnMobile = useMediaQuery({
    query: device.mobile,
  });

  const query = useQuery();
  const period = query.get("period");

  return (
    <TransparentHeader
      background={isOnEnterSpherePage ? "transparent" : "#fff"}
    >
      <NavLink to="/profile">
        <RedLogoLink
          src={
            isOnEnterSpherePage && isOnMobile && !period ? LinkLogo : RedLogo
          }
          srcSet={
            isOnEnterSpherePage && isOnMobile && !period
              ? `${LinkLogo},
                    ${RetinaLinkLogo} 2x`
              : `${RedLogo},
            ${RetinaRedLogo} 2x`
          }
          alt="logo"
        />
      </NavLink>
      <HeaderCol offset={20} span={4}>
        <HeaderMenu showDropdown={false} />
      </HeaderCol>
    </TransparentHeader>
  );
};

const TransparentHeader = styled(Header)`
  background: #fff;
  position: relative;
  height: 10vh;
  min-height: 90px;
  @media ${device.mobileS} {
    min-height: 55px;
  }
  @media ${device.mobile} {
    background: ${(prop) => prop.background};
    max-height: 80px;
    padding: 0;
    .ant-menu-horizontal {
      line-height: 35px;
      max-width: 40px;
    }
  }
  @media (min-width: 414px) and (max-width: 959px) {
    min-height: 70px;
  }
  @media ${device.largeDevice} {
    min-height: 100px;
  }
  @media ${device.tablet} {
    max-height: 90px;
    height: 90px;
  }
`;

const HeaderCol = styled(Col)`
  margin-top: 30px;
  text-align: -webkit-right;
  @media ${device.mobile} {
    margin-top: 0;
    padding: 0 10px;
  }
`;

const RedLogoLink = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  max-width: 45px;
  padding-top: 10px;
  @media ${device.mobileS} {
    max-width: 45px;
    padding-top: 10px;
  }
  @media ${device.mobileL} {
    max-width: 50px;
    padding-top: 15px;
  }
  @media ${device.desktop} {
    max-width: 95px;
    padding-top: 30px;
  }
  @media ${device.laptop} {
    max-width: 75px;
    padding-top: 30px;
  }
  @media (min-width: 960px) and (max-width: 1280px) {
    max-width: 70px;
    padding-top: 30px;
  }
`;

export default PageHeader;
