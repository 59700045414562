const types = {
  // Goals
  LOAD_GOALS: "LOAD_GOALS",
  ADD_NEW_GOAL: "ADD_NEW_GOAL",
  DELETE_GOAL: "DELETE_GOAL",
  EDIT_GOAL: "EDIT_GOAL",
  GOALS_LOADING: "GOALS_LOADING",
  SET_GOAL_TO_EDIT: "SET_GOAL_TO_EDIT",
  // Tasks
  TASKS_LOADING: "TASKS_LOADING",
  UPDATE_TASK: "UPDATE_TASK",
  NEW_VERSION: "NEW_VERSION",
  SET_TASKS: "SET_TASKS",
  DELETE_TASK: "DELETE_TASK",
  SET_SUBTASKS: "SET_SUBTASKS",
  ADD_NEW_SUBTASK: "ADD_NEW_SUBTASK",
  DELETE_SUBTASK: "DELETE_SUBTASK",
  // Common
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  SET_LANGUAGE: "SET_LANGUAGE",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  LOG_OUT: "LOG_OUT",
  SET_AVATAR: "SET_AVATAR",
  UPDATE_FIELD: "UPDATE_FIELD",
  SET_PERIOD: "SET_PERIOD",
  SET_CATEGORY: "SET_CATEGORY",
};

export default types;
