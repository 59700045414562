import types from "../../actionTypes";

const initialState = {};

const ActivePeriodReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_PERIOD:
      return payload;
    default:
      return state;
  }
};

export default ActivePeriodReducer;
