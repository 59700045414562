import types from "../../actionTypes";

const initialState = {
  isLoaded: false,
  isLoading: false,
  items: [],
};

const GoalsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOAD_GOALS:
      return {
        isLoading: false,
        isLoaded: true,
        items: [...state.items, ...payload.results]
      }
    case types.ADD_NEW_GOAL:
      return {
        ...state,
        items: [payload, ...state.items]
      }
    case types.DELETE_GOAL:
      return {
        ...state,
        items: state.items.filter((goal) => goal.id !== payload)
      }
    case types.EDIT_GOAL:
      return {
        ...state,
        items: state.items.map((goal) => goal.id === payload.id ? {...goal, ...payload} : goal)
      }
    case types.GOALS_LOADING:
      return {
        ...state,
        isLoading: payload
      }
    default:
      return state;
  }
};

export default GoalsReducer;
