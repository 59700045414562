import React from "react";
import styled from "styled-components";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import device from "../../common/deviceSizes";
import { useQuery, SPHERESLIST } from "../../common/helpers";
import { ReactComponent as MailIcon } from "../../assets/envelope.svg";
import { ReactComponent as UserIcon } from "../../assets/user.svg";
import { ReactComponent as SettingsIcon } from "../../assets/settings-menu.svg";

const HeaderMenu = ({ showDropdown = false }) => {
  const { pathname } = useLocation();
  const isOnEnterSpherePage = SPHERESLIST.find(
    (sphere) => sphere.path === pathname
  );
  const isOnTablet = useMediaQuery({
    query: device.tablet,
  });

  const query = useQuery();
  const period = query.get("period");

  return (
    showDropdown ? (
      <StyledMenu
        mode="horizontal"
        background={isOnEnterSpherePage ? "transparent" : "#fff"}
        hamburgercolor={
          isOnEnterSpherePage && !isOnTablet && !period ? "#fff" : "#d2d8dd"
        }
      >
        <Menu.Item key="mail">
          <MailIcon />
        </Menu.Item>
        <Menu.Item key="user">
          <UserIcon />
        </Menu.Item>
        <Menu.Item key="settings">
          <NavLink to="/settings">
            <SettingsIcon />
          </NavLink>
        </Menu.Item>
      </StyledMenu>
    ) : (
      <NavLink to="/settings">
        <SettingsIcon />
      </NavLink>
    )
  );
};

const StyledMenu = styled(Menu)`
  li.ant-menu-item.ant-menu-item-selected,
  li.ant-menu-item.ant-menu-item-active {
    border-bottom: 2px solid #bebdbd;
  }
  border-bottom: none;
  @media (min-width: 959px) and (max-width: 960px) {
    width: 60px;
    background: ${(prop) => prop.background};
    span {
      opacity: 0;
    }
    .ant-menu-submenu-title::before {
      content: "\u2261";
      font-size: 50px;
      width: 100%;
      font-weight: 600;
      top: 0px;
      color: ${(prop) => prop.hamburgercolor};
    }
  }
  @media (max-width: 959px) {
    background: ${(prop) => prop.background};
    span {
      opacity: 0;
    }
    .ant-menu-submenu-title::before {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0px;
      width: 1.2em;
      height: 0.2em;
      background: ${(prop) => prop.hamburgercolor};
      box-shadow: 0 0.25em 0 0 ${(prop) => prop.hamburgercolor},
        0 0.5em 0 0 ${(prop) => prop.hamburgercolor};
    }
    li.ant-menu-submenu {
      border: none !important;
    }
    .ant-menu-submenu-title:hover,
    .ant-menu-submenu-title {
      height: 15px;
    }
  }
  @media (min-width: 960px) and (max-width: 1280px) {
    width: 60px;
    background: ${(prop) => prop.background};
    span {
      opacity: 0;
    }
    .ant-menu-submenu-title::before {
      content: "\u2261";
      font-size: 50px;
      width: 100%;
      font-weight: 600;
      top: 0px;
      color: ${(prop) => prop.hamburgercolor};
    }
    li.ant-menu-submenu {
      border: none !important;
    }
    .ant-menu-submenu-title:hover,
    .ant-menu-submenu-title {
      height: 35px;
      padding: 0;
    }
  }
  @media ${device.largeDevice} {
    min-width: fit-content;
  }
`;

export default HeaderMenu;
