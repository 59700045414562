import React, { Suspense } from "react";
import { Spin } from "antd";
import styled from "styled-components";

import { SPHERESLIST } from "../common/helpers";

const LazyRegistration = React.lazy(() =>
  import("../pages/Registration/Registration")
);
const LazyHome = React.lazy(() => import("../pages/Home/Home"));
const LazyProfile = React.lazy(() => import("../pages/Profile/Profile"));
const LazyCharts = React.lazy(() => import("../pages/Charts/Charts"));
const LazySettings = React.lazy(() => import("../pages/Settings/Settings"));
const LazySpherePage = React.lazy(() => import("../pages/Sphere/SpherePage"));
const LazyCharity = React.lazy(() => import("../pages/Charity/Charity"));
const LazyCharityProjectPage = React.lazy(() =>
  import("../pages/Charity/CharityProjects/CharityProjectPage")
);
const LazyCharityFundPage = React.lazy(() =>
  import("../pages/Charity/CharityFund/CharityFundPage")
);

const CentralSuspense = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

export const enterRoute = {
  pathname: "/",
  component: (
    <Suspense fallback={<CentralSuspense size="large" />}>
      <LazyRegistration />
    </Suspense>
  ),
  exact: true,
};

const routes = [
  {
    pathname: "/profile",
    component: (
      <Suspense fallback={<CentralSuspense size="large" />}>
        <LazyProfile />
      </Suspense>
    ),
    exact: false,
  },
  {
    pathname: "/charts",
    component: (
      <Suspense fallback={<CentralSuspense size="large" />}>
        <LazyCharts />
      </Suspense>
    ),
    exact: false,
  },
  ...SPHERESLIST.map((item) => ({
    pathname: item.path,
    component: (
      <Suspense fallback={<CentralSuspense size="large" />}>
        <LazySpherePage sphere={item.sphere} />
      </Suspense>
    ),
    exact: false,
  })),
  {
    pathname: "/settings",
    component: (
      <Suspense fallback={<CentralSuspense size="large" />}>
        <LazySettings />
      </Suspense>
    ),
    exact: false,
  },
  {
    pathname: ["/charity/nature", "/charity/human", "/charity/animal"],
    component: (
      <Suspense fallback={<CentralSuspense size="large" />}>
        <LazyCharity />
      </Suspense>
    ),
    exact: true,
  },

  {
    pathname: "/charityProject/",
    component: (
      <Suspense fallback={<CentralSuspense size="large" />}>
        <LazyCharityProjectPage />
      </Suspense>
    ),
    exact: true,
  },

  {
    pathname: "/charityFund/",
    component: (
      <Suspense fallback={<CentralSuspense size="large" />}>
        <LazyCharityFundPage />
      </Suspense>
    ),
    exact: true,
  },
  {
    pathname: "/",
    component: (
      <Suspense fallback={<CentralSuspense size="large" />}>
        <LazyHome />
      </Suspense>
    ),
    exact: true,
  },
  {
    component: (
      <Suspense fallback={<CentralSuspense size="large" />}>
        <LazyHome />
      </Suspense>
    ),
  },
];

export default routes;
