import types from "../../actionTypes";

const initialState = localStorage.getItem("lang") || window.navigator.userLanguage || window.navigator.language;

const LanguageReducer = (state = initialState, { type, payload }) => {
  const supportedLanguages = ['en', 'uk', 'ru'];
  const mapLanguage = langCode => {
    const lang2Letter = (String(langCode)).substr(0, 2);
    return supportedLanguages.includes(lang2Letter) ? lang2Letter : supportedLanguages[0];
  };

  switch (type) {
    case types.SET_LANGUAGE:
      return mapLanguage(payload);
    default:
      return mapLanguage(state);
  }
};

export default LanguageReducer;
