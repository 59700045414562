import React, { useState } from "react";
import { Layout } from "antd";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { useQuery, SPHERESLIST } from "../../common/helpers";
import device from "../../common/deviceSizes";

const { Content } = Layout;

const AppLayout = ({ children }) => {
  const query = useQuery();
  const period = query.get("period");
  const isTaskOpen = query.get("action") === "task";

  const { pathname } = useLocation();

  const isOnMobile = useMediaQuery({
    query: device.mobile,
  });
  const isOnLargeDevice = useMediaQuery({
    query: device.largeDevice,
  });

  const [isMenuExpanded, setMenuExpanded] = useState(isOnMobile ? false : true);
  const isOnEnterSpherePage = SPHERESLIST.find(
    (sphere) => sphere.path === pathname
  );

  return (
    <MainLayout
      background={
        isOnEnterSpherePage && !period
          ? "linear-gradient(180deg, #F75C72 0%, #1D4E89 100%)"
          : "#fff"
      }
    >
      {(!isTaskOpen || isOnLargeDevice) && (
        <Sidebar
          isMenuExpanded={isMenuExpanded}
          setMenuExpanded={setMenuExpanded}
        />
      )}
      <Layout
        onClick={() => {
          isOnMobile && setMenuExpanded(false);
        }}
      >
        {(!isTaskOpen || isOnLargeDevice) && <Header />}
        <MainContent
          padding={pathname === "/profile" || isTaskOpen ? "0" : "30px"}
          istaskopen={isTaskOpen ? "0" : "10px"}
          background={
            isOnEnterSpherePage
              ? "transparent"
              : pathname === "/profile"
              ? "linear-gradient(180deg, #80557f 50%, #3d5086 100%)"
              : "#fff"
          }
          hasperiod={period}
          pathname={pathname}
        >
          {children}
        </MainContent>
        {!isTaskOpen && <Footer />}
      </Layout>
    </MainLayout>
  );
};

const MainContent = styled(Content)`
  background-color: #fff;
  padding-left: ${(prop) => prop.pathname.includes("/charity") ? 0 : prop.padding};
  @media ${device.mobile} {
    padding-left: ${(prop) => prop.istaskopen};
    background: ${(prop) => prop.background};
  }
  @media ${device.tablet} {
    padding-left: ${(prop) => prop.pathname.includes("/charity") ? 0 : prop.padding};
  }
  //!
  @media ${device.largeDevice} {
    padding-left: ${(prop) => prop.pathname.includes("/charity") ? 0 : prop.padding};
  }

  height: 100%;
  
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const MainLayout = styled(Layout)`
  height: 100vh;
  max-height: 100vh;
  background: #fff;
  @media ${device.mobile} {
    .ant-layout {
      background: ${(prop) => prop.background};
    }
  }
`;

export default AppLayout;
