import { combineReducers } from "redux";
import GoalsReducer from "./tasks/GoalsReducer";
import TaskReducer from "./tasks/TaskReducer";
import SubtaskReducer from "./tasks/SubtaskReducer";
import AuthReducer from "./auth/AuthReducer";
import LanguageReducer from "./language/LanguageReducer";
import LoadingReducer from "./tasks/LoadingReducer";
import ActivePeriodReducer from "./period/ActivePeriodReducer";
import GoalToEditReducer from "./tasks/GoalToEdit";
import UserReducer from "./user/UserReducer";

const MainReducer = combineReducers({
  goals: GoalsReducer,
  tasks: TaskReducer,
  subtasks: SubtaskReducer,
  auth: AuthReducer,
  language: LanguageReducer,
  loading: LoadingReducer,
  activePeriod: ActivePeriodReducer,
  outsideTaskToEdit: GoalToEditReducer,
  user: UserReducer
});

export default MainReducer;
