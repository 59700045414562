import types from "../actionTypes";
import i18n from "../../translations/i18n";

export const SetLanguageActionCreator = (language) => ({
  type: types.SET_LANGUAGE,
  payload: language
});

export const SwitchLanguageAction = (language) => {
  return (dispatch, getState) => {
    localStorage.setItem("lang", language);
    i18n.changeLanguage(language);
    dispatch(SetLanguageActionCreator(language));
  }
}